<template>
  <div class="home-container">
    <div class="red-square" />
    <div class="home">
      <img src="../assets/circle1.png" class="circle1" alt="circle1 image" />
      <img src="../assets/circle2.png" class="circle2" alt="circle2 image" />
      <div class="logo">
        <img src="../assets/ImpossibleSelfielogo.svg" alt="SoFi Logo" />
      </div>
      <div class="video">
        <div class="video-container">
          <not-found-video v-if="invalidVideo" />
          <LoadingVideo v-if="sendingEmail" />
          <video
            id="myvideo"
            class="video-player"
            playsinline
            autoplay
            muted
            loop
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div class="hero-text">
        <h3 v-if="step === 0">Where should we email your video?</h3>
        <h3 v-if="step === 1">Sending to:</h3>
        <h3 v-if="step === 2">Success! Check your email.</h3>
      </div>
      <div v-if="step === 0">
        <EmailInput
          v-if="!sent_email"
          :index="0"
          :email="emailsObj[0].email"
          :step="0"
          @email-updated="handleEmail"
        />
        <div class="terms-container">
          <label class="terms">
            I acknowledge that I have read SoFi's
            <a href="https://www.sofi.com/glba-privacy-notice/" target="_blank"
              >Privacy Policy</a
            >

            <input
              type="checkbox"
              v-model="termsChecked"
              :disabled="
                !emailsObj[0].email || !emailsObj[0].valid || invalidVideo
              "
              @click.stop="stopTheEvent"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div v-if="step === 1">
        <template v-if="!sent_email">
          <EmailInput
            v-for="(email, index) in emailsObj"
            :key="index"
            :email="email.email"
            :index="index"
            @email-updated="handleEmail"
          />
        </template>
      </div>
    </div>
    <transition name="fade" mode="out-in" v-if="step !== 0">
      <div
        class="send-container"
        :class="{ sent: sent_email }"
        @click="sendEmail"
      >
        <h3 :class="{ sent: sent_email }">
          {{ sent_email ? "Sent!" : "Send" }}
        </h3>
        <div class="arrow" v-if="!sent_email">
          <div class="line"></div>
          <div class="point"></div>
        </div>
      </div>
    </transition>
  </div>
  <Loading v-if="sendingEmail" />
</template>

<script>
import { functions } from "@/firebase/config";
import { onMounted, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import EmailInput from "@/components/EmailInput";
import LoadingVideo from "@/components/LoadingVideo";
import NotFoundVideo from "@/components/NotFoundVideo";
import Loading from "@/components/Loading";
import { httpsCallable } from "firebase/functions";

export default {
  name: "Home",
  components: {
    EmailInput,
    LoadingVideo,
    NotFoundVideo,
    Loading,
  },

  setup: function() {
    const sent_email = ref(false);
    const step = ref(0);
    const termsChecked = ref(false);
    const route = useRoute();
    const videoID = ref(route.params.videoID);
    const emailsObj = reactive([
      {
        email: "",
        valid: false,
      },
    ]);
    const sendingEmail = ref(false);
    const invalidVideo = ref(false);

    onMounted(() => {
      const videoUrl =
        "https://storage.googleapis.com/sofi-impossibleselfie.appspot.com/" +
        videoID.value;
      fetch(videoUrl, {
        headers: { "Access-Control-Allow-Origin": "*" },
      })
        .then((response) => {
          if (!response.ok) {
            console.log(response);
            invalidVideo.value = true;
            alert("Video still uploading, refresh this page");
          } else {
            invalidVideo.value = false;
            const el = document.getElementById("myvideo");
            el.src = videoUrl;
          }
        })
        .catch((error) => {
          console.log(error);
          invalidVideo.value = true;
          alert("Video still uploading, refresh this page");
        });
    });

    const visitMicroSite = httpsCallable(functions, "visitMicroSite");
    visitMicroSite({ videoID: videoID.value })
      .then((result) => {
        // Read result of the Cloud Function.
        // console.log(result.data);
      })
      .catch((error) => {
        console.log(error);
      });

    onMounted(() => {
      const video = document.getElementById("myvideo");
      video.style.borderRadius = "10px";
    });

    watch(termsChecked, () => {
      if (
        termsChecked.value &&
        emailsObj[0].email &&
        emailsObj[0].valid &&
        !invalidVideo.value
      ) {
        step.value = 1;
      }
    });

    watch(emailsObj, () => {
      if (emailsObj.length === 0) {
        emailsObj.push({
          email: "",
          valid: false,
        });
        termsChecked.value = false;
        step.value = 0;
      }
    });

    const sendEmail = () => {
      if (sent_email.value) return;

      sendingEmail.value = true;
      const invalidEmails = emailsObj.some(
        (obj) => obj.email && obj.valid === false
      );
      const noEmails = emailsObj.some((obj) => obj.email !== "");
      if (!noEmails) {
        alert("You must add at least one valid email!");
        return;
      }
      if (invalidEmails) {
        alert("Please enter a valid email");
        return;
      }

      let emails = emailsObj
        .filter(
          (emailObj) =>
            emailObj.email && emailObj.email !== "" && emailObj.valid
        )
        .map((emailObj) => emailObj.email);

      if (emails.length > 0) {
        const sendEmails = httpsCallable(functions, "sendEmails");
        sendEmails({ emails: emails, videoID: videoID.value })
          .then((result) => {
            // Read result of the Cloud Function.
            console.log(result.data);
            sendingEmail.value = false;
            step.value = 2;
            sent_email.value = true;
            window.setTimeout(function() {
              window.location.replace("https://www.sofi.com/stadium/");
            }, 500);
          })
          .catch((error) => {
            sendingEmail.value = false;
            alert("There is a problem with sending emails! Try it later!");
            console.log(error);
          });
      }
    };

    const handleEmail = (index, email, valid) => {
      emailsObj[index] = {
        email,
        valid,
      };
    };

    return {
      emailsObj,
      sent_email,
      sendEmail,
      step,
      termsChecked,
      handleEmail,
      videoID,
      sendingEmail,
      invalidVideo,
    };
  },
};
</script>

<style lang="scss">
.home-container {
  width: 100%;
  min-height: 100vh;
  background: #00a9ce;
  position: relative;
  overflow: hidden;

  .red-square {
    position: absolute;
    top: 30px;
    right: -50px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #e03e52;
  }

  .home {
    max-width: 1440px;
    margin: 60px auto auto;
    padding-bottom: 150px;

    .circle1 {
      position: absolute;
      width: 160px;
      top: 60px;
      left: -55px;
      transform: scaleX(-1);
      opacity: 0.5;
    }

    .circle2 {
      position: absolute;
      width: 160px;
      top: 40%;
      right: -90px;
      transform: scaleX(-1);
      opacity: 0.5;
    }

    .logo {
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;

      img {
        width: 155px;
      }
    }

    .video {
      padding: 20px;
      max-width: 800px;
      margin: auto;
      .video-container {
        border-radius: 10px;
        width: 100%;
        padding-top: 56.25%;
        position: relative;
        overflow: hidden;

        .video-player {
          position: absolute;
          top: -1px;
          left: 0;
          width: 100%;
          height: 101%;
        }
      }
    }

    .hero-text {
      text-align: center;
      color: #201747;

      h3 {
        font-size: 18px;
        font-family: larsseitbold;
      }
    }

    .terms-container {
      width: 100%;
      max-width: 295px;
      margin: auto;
      padding-top: 10px;

      .terms {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 12px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: white;

        a {
          color: white;
        }

        &:hover input ~ .checkmark {
          background-color: #00a9ce;
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked ~ .checkmark {
            background-color: #2196f3;
          }

          &:checked ~ .checkmark:after {
            display: block;
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #00a9ce;
          border: 3px solid #15788f;
          border-radius: 5px;

          &:after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        b {
          text-decoration: underline;
          font-family: larsseitbold;
        }
      }
    }

    .additional_email {
      width: 100%;
      max-width: 295px;
      margin: 10px auto;

      button {
        width: 100%;
        background: white;
        border: none;
        border-radius: 10px;
        font-size: 16px;
        color: #00a9ce;
        padding: 15px;
      }
    }
  }

  .send-container {
    background: white;
    width: 100%;
    max-width: 375px;
    height: 100px;
    border-radius: 70% 70% 0 0;
    text-align: center;
    padding-top: 30px;
    cursor: pointer;
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    animation: move-up 1s linear;

    &.sent {
      background: #201747;
      cursor: default;
    }

    h3 {
      color: #201747;
      font-size: 17px;
      font-family: Larsseit-ExtraBold;

      &.sent {
        color: white;
      }
    }

    .arrow {
      width: 16px;
      margin: auto;

      .line {
        margin-top: 4px;
        width: 10px;
        background: #201747;
        height: 2px;
        float: left;
      }

      .point {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 6px solid #201747;
        float: right;
      }
    }

    @keyframes move-up {
      0% {
        transform: translate(-50%, 200px);
      }
      100% {
        transform: translate(-50%, 0);
      }
    }
  }
}
</style>
