<template>
  <div class="loadingVideo">
    <LottieAnimationNew
      path="lotties/loading.json"
      :loop="true"
      :autoPlay="true"
      :speed="1"
    />
  </div>
</template>

<script>
import LottieAnimationNew from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
  components: { LottieAnimationNew },
};
</script>

<style lang="scss" scoped>
.loadingVideo {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
}
</style>
