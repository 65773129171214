<template>
 <div id="message">
    <h2>Page Not Found</h2>
  </div>
</template>

<script>

export default {
  name: '404 Page Not Found',
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'Page Not Found';
      }
    },
  }
}
</script>

<style>
  body {
    background: #ECEFF1;
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto, Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  #message {
    background: white;
    max-width: 360px;
    margin: 100px auto 16px;
    padding: 32px 24px 16px;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  #message h2 {
    color: #ffa100;
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 8px;
  }


  @media (max-width: 600px) {
    body,
    #message {
      margin-top: 0;
      background: white;
      box-shadow: none;
    }
    body {
      border-top: 16px solid #ffa100;
    }
  }
</style>
