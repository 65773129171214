<template>
  <Header />
  <router-view />
</template>
<script>
import Header from "@/components/Header.vue";

export default {
  components: {
    Header
  },

  setup() {},
};
</script>

<style lang="scss">
  @font-face {
    font-family: "Larsseit-ExtraBold";
    src: local("Larsseit-ExtraBold"),
      url(./assets/fonts/Larsseit-ExtraBold.ttf) format("truetype");
  }

  @font-face {
    font-family: "larsseit";
    src: local("larsseit"),
      url(./assets/fonts/larsseit.ttf) format("truetype");
  }

  @font-face {
    font-family: "larsseitbold";
    src: local("larsseitbold"),
      url(./assets/fonts/larsseitbold.ttf) format("truetype");
  }

  body {
    margin: 0;
    font-family: larsseit;
  }

  input:focus {
    outline: none;
  }

  button {
    cursor: pointer;
  }

  p {
    margin: 0;
  }
</style>
