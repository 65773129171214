import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import PageNotFound from '../views/PageNotFound.vue';

const routes = [
  {
    path: '/v/:videoID',
    name: 'Home',
    component: Home
  },

  {
    path: '/:pathMatch(.*)*',
    component: PageNotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
