<template>
  <div class="email">
    <div class="input-section">
      <input
        :class="{
          invalid: !validEmail,
          defaultEmail: props.index === 0 && props.step !== 0,
        }"
        type="text"
        placeholder="Enter email address here"
        :value="props.email"
        @change="handleEmail"
        :readonly="props.index === 0 && props.step !== 0"
      />
      <p class="error" v-if="!validEmail">Please enter a valid email</p>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  name: "Email Input",
  emits: ["email-updated", "email-subtract"],
  props: ["index", "email", "step"],
  setup(props, context) {
    const validEmail = ref(true);

    const handleEmail = (e) => {
      const value = e.target.value;

      if (!validateEmail(value)) {
        validEmail.value = false;
      } else {
        validEmail.value = true;
      }

      if (!value) validEmail.value = true;

      context.emit("email-updated", props.index, value, validEmail.value);
    };

    const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    };

    return {
      props,
      validEmail,
      handleEmail,
    };
  },
};
</script>

<style lang="scss" scoped>
.email {
  width: 100%;
  max-width: 295px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;

  .input-section {
    width: 100%;

    input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      text-align: center;
      padding: 15px 30px;
      box-sizing: border-box;
      z-index: 1;
      border: none; /* <-- This thing here */
      border: solid 3px #006c85;
      font-size: 14px;
      color: white;
      background: #006c856b;
      display: block;

      &.invalid {
        border-color: red;
      }

      &.defaultEmail {
        background: #097189;
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: white;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: white;
      }
    }
    input:focus::placeholder {
      color: transparent;
    }
    .error {
      color: red;
    }
  }
}
</style>
