<template>
  <div class="notfound">
    <LottieAnimationNew
      path="lotties/notfound.json"
      :loop="true"
      :autoPlay="true"
      :speed="1"
    />
  </div>
</template>

<script>
import LottieAnimationNew from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
  components: { LottieAnimationNew },
};
</script>

<style lang="scss" scoped>
.notfound {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 999;
  top: 0;
  left: 0;
}
</style>
