import { getApp, initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


const firebaseConfig = {
  apiKey: "AIzaSyDOzGtBqDnicgjvxrs5F0Q8AsjReQYK7fk",
  authDomain: "sofi-impossibleselfie.firebaseapp.com",
  projectId: "sofi-impossibleselfie",
  storageBucket: "sofi-impossibleselfie.appspot.com",
  messagingSenderId: "482358280516",
  appId: "1:482358280516:web:bf159c454cdad020969711",
  measurementId: "G-Y2YFQ5ZB14"
};

let firebaseApp;
try {
  firebaseApp = getApp();
} catch (e) {
  firebaseApp = initializeApp(firebaseConfig);
}


const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);

if((typeof window === "undefined" || window.location.hostname === 'localhost') && process.env.VUE_APP_FIREBASE_EMULATOR) {
  console.log("Using Firebase emulator.");
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
} else {
  console.log("Using Firebase cloud.")
}


export { firebaseApp, firestore, functions };
