<template>
  <div class="header-container">
    <div class="header">
      <div>
        <img class="logo" src="../assets/logo.svg" alt="SoFi Logo" />
      </div>
      <div>
        <button class="login" @click="login()">Become a member</button>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router/index";

export default {
  setup() {
    const goToMain = () => {
      router.push("/");
    };

    const login = () => {
      window.location.href = "https://www.sofi.com/sofi-stadium/#products";
    };

    return {
      goToMain,
      login,
    };
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999;
  background: white;

  .header {
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    height: 30px;
    margin: auto;

    .logo {
      height: 100%;
      cursor: pointer;
    }

    .login {
      font-size: 14px;
      font-family: larsseitbold;
      background: #006b87;
      color: white;
      height: 100%;
      border: 2px solid transparent;
      transition: all 0.3s ease-out;

      &:hover {
        background: #fff;
        border-color: #006b87;
        color: #006b87;
      }
    }
  }
}
</style>
